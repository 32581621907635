import { useApi } from '~/composables/useApi';

export const useFtGetCartTotal = () => {
  const { query } = useApi();

  const cartTotalAndTotalQty:any = async (cartId) =>{
    const myGraphqlQuery = `
      query cartTotalAndTotalQty($cartId: String!) {
        cart(cart_id: $cartId) {
          id
          total_quantity
          prices {
            subtotal_excluding_tax {
              value
            }
            subtotal_including_tax {
              value
            }
            applied_taxes {
              amount {
                value
              }
              label
            }
            discounts {
              amount {
                value
              }
              label
            }
            grand_total {
              value
            }
          }
        }
      }
      `;  
    const { data } = await query(myGraphqlQuery, {cartId: cartId});

    return {
      data,
    };
  }
  
  return {
    cartTotalAndTotalQty,
  }
}


export default useFtGetCartTotal;