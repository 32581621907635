/* eslint-disable  */

export default {
  "About us": "About us",
  "Account": "Account",
  "Add New Address": "Add New Address",
  "Add to compare": "Add to compare",
  "Add to Wishlist": "Add to Wishlist",
  "Additional Information": "Additional Information",
  "All Orders": "All Orders",
  "Allow order notifications": "Allow order notifications",
  "Apply": "Apply",
  "Applied Coupon": "Applied Coupon",
  "Attention!": "Attention!",
  "Back to home": "Back to home",
  "Back to homepage": "Back to homepage",
  "Billing": "Billing",
  "Billing address": "Billing address",
  "Brand": "Brand",
  "Cancel": "Cancel",
  "Cart": "Cart",
  "Categories": "Categories",
  "Change": "Change",
  "Clear all": "Clear all",
  "Clear all filters":"Clear all filters",
  "Color": "Color",
  "Commercial information": "and agree to receive commercialised branded information from FortyTwo Pte Ltd. via email",
  "Contact details updated": "Keep your addresses and contact details updated.",
  "Contact us": "Contact us",
  "Continue to billing": "Continue to billing",
  "Continue to payment": "Continue to payment",
  "Continue to shipping": "Continue to shipping",
  "Cookies Policy": "Cookies Policy",
  "Create an account": "Create an account",
  "Customer Reviews": "Customer Reviews",
  "Customer service": "Customer service",
  "Date": "Date",
  "Default Billing Address": "Default Billing Address",
  "Default Shipping Address": "Default Shipping Address",
  "Default Delivery Address": "Default Delivery Address",
  "Delete": "Delete",
  "Departments": "Departments",
  "Description": "Description",
  "Details and status orders": "Check the details and status of your orders in the online store. You can also cancel your order or request a return.",
  "Discount": "Discount",
  "Done": "Done",
  "Download": "Download",
  "Download all": "Download all",
  "Edit": "Edit",
  "Email address": "Email address",
  "Empty": "Looks like you haven’t added any items to the bag yet. Start shopping to fill it in.",
  "Empty bag": "Empty bag",
  "Enjoy your free account": "Enjoy these perks with your free account!",
  "Enter promo code": "Enter promo code",
  "Feedback": "Your feedback is important to us. Let us know what we could improve.",
  "Feel free to edit": "Edit your particulars and keep your account up to date",
  "Filters": "Filters",
  "Find out more": "Find out more",
  "First Name": "First Name",
  "Forgot Password": "If you can’t remember your password, you can reset it.",
  "Forgot Password Modal Email": "Email you are using to sign in:",
  "forgotPasswordConfirmation": "Thanks! If there is an account registered with the {0} email, you will find message with a password reset link in your inbox.",
  "Forgotten password?": "Forgotten password?",
  "Go back": "Go back",
  "Go back shopping": "Go back shopping",
  "Go back to shop": "Go back to shop",
  "Go to checkout": "Go to checkout",
  "Go to cart": "Go to cart",
  "Guarantee": "Guarantee",
  "Help": "Help",
  "Help & FAQs": "Help & FAQs",
  "hide": "hide",
  "Home": "Home",
  "I agree to": "I agree to",
  "I confirm subscription": "I confirm subscription",
  "I want to create an account": "I want to create an account",
  "Info after order": "You can log to your account using e-mail and password defined earlier. On your account you can edit your profile data, check history of transactions, edit subscription to newsletter.",
  "Instruction1": "Take care of me",
  "Instruction2": "Just here for the care instructions?",
  "Instruction3": "Yeah, we thought so",
  "It was not possible to request a new password, please check the entered email address.": "It was not possible to request a new password, please check the entered email address.",
  "Item": "Item",
  "Items": "Items",
  "Kidswear": "Kidswear",
  "Last Name": "Last Name",
  "Let’s start now – we’ll help you": "Let’s start now – we’ll help you.",
  "Log into your account": "Log into your account",
  "Login": "Login",
  "login in to your account": "login in to your account",
  "Looks like you haven’t added any items to the bag yet. Start shopping to fill it in.": "Looks like you haven’t added any items to the bag yet. Start shopping to fill it in.",
  "Looks like you haven’t added any items to the Wishlist.": "Looks like you haven’t added any items to the Wishlist.",
  "Make an order": "Make an order",
  "Manage addresses": "Manage all the addresses you want (work place, home address...) This way you won\"t have to enter the address manually with each order.",
  "Manage billing addresses": "Manage all the billing addresses you want (work place, home address...) This way you won\"t have to enter the billing address manually with each order.",
  "Manage shipping addresses": "Manage all the shipping addresses you want (work place, home address...) This way you won\"t have to enter the shipping address manually with each order.",
  "Match it with": "Match it with",
  "Men fashion": "Men fashion",
  "Menu": "Menu",
  "Use the address above as your billing address.": "Use the address above as your billing address.",
  "My Cart": "My Cart",
  "No account": "Don't have an account yet?",
  "or": "or",
  "or fill the details below": "or fill the details below",
  "Order ID": "Order ID",
  "Order information": "Order information",
  "Order No.": "Order No.",
  "Order summary": "Order summary",
  "Other products you might like": "Other products you might like",
  "Out of stock": "Out of stock",
  "Password": "Password",
  "Password Changed": "Password successfuly changed. You can now go back to homepage and sign in.",
  "Pay for order": "Pay for order",
  "Payment": "Payment",
  "Payment & Delivery": "Payment & Delivery",
  "Payment Method": "Payment Method",
  "Payment Methods": "Payment Methods",
  "Personal details": "Personal details",
  "Please type your current password to change your email address.": "Please type your current password to change your email address.",
  "Price": "Price",
  "Privacy": "Privacy",
  "Privacy Policy": "Privacy Policy",
  "Product": "Product",
  "Product suggestions": "Product suggestions",
  "Products": "Products",
  "Products found": "Products found",
  "Purchase terms": "Purchase terms",
  "Quality in the details": "Quality in the details",
  "Quantity": "Quantity",
  "Read all reviews": "Read all reviews",
  "Read and understand": "I have read and understood the",
  "Read reviews": "Read reviews",
  "Register": "Register",
  "Register today": "Register today",
  "Remove": "Remove",
  "Remove Address": "Remove Address",
  "Remove from Wishlist": "Remove from Wishlist",
  "Repeat Password": "Repeat Password",
  "Confirm New Password":"Confirm New Password",
  "Reset Password": "Reset Password",
  "Review my order": "Review my order",
  "Same as shipping address": "Same as shipping address",
  "Save Changes": "Save Changes",
  "Save for later": "Save for later",
  "Save Password": "Save Password",
  "Search": "Search",
  "Search for items": "Search for items",
  "Search results": "Search results",
  "Sections that interest you": "Sections that interest you",
  "See all results": "See all results",
  "See more": "See more",
  "Select payment method": "Select payment method",
  "Select shipping method": "Select shipping method",
  "Send my feedback": "Send my feedback",
  "Set up newsletter": "Sign up for our newsletter to get the latest updates and happenings at FortyTwo.",
  "Share your look": "Share your look",
  "Shipping": "Shipping",
  "Shipping address": "Shipping address",
  "Shipping details": "Shipping details",
  "Shipping method": "Shipping method",
  "Show":"Show",
  "show more": "show more",
  "Show on page": "Show on page",
  "Sign in": "Sign in",
  "Size guide": "Size guide",
  "Sign Up for Newsletter": "Sign Up for Newsletter",
  "Subscribe To Our Newsletter":"Subscribe To Our Newsletter",
  "Sort by": "Sort by",
  "Sort: Default": "Default",
  "Sort: Name A-Z": "Name A-Z",
  "Sort: Name Z-A": "Name Z-A",
  "Sort: Price from high to low": "Price from high to low",
  "Sort: Price from low to high": "Price from low to high",
  "Start shopping": "Start shopping",
  "Status": "Status",
  "Subscribe": "Subscribe",
  "Subscribe to newsletter": "Subscribe to newsletter",
  "subscribeToNewsletterModalContent": "After signing up for the newsletter, you will receive special offers and messages from VSF via email. We will not sell or distribute your email to any third party at any time. Please see our {0}.",
  "Subtotal": "Subtotal",
  "Subtotal price": "Subtotal price",
  "Successful placed order": "You have successfully placed the order. You can check status of your order by using our delivery status feature. You will receive an order confirmation e-mail with details of your order and a link to track its progress.",
  "Terms and conditions": "Terms and conditions",
  "Thank you": "Thank you",
  "Thank You Inbox": "If the message is not arriving in your inbox, try another email address you might’ve used to register.",
  "Total": "Total",
  "Total Order": "Total Order",
  "Total items": "Total items",
  "Total price": "Total price",
  "Update Password": "Update Password",
  "Update Personal Data": "Update Personal Data",
  "At FortyTwo, we are committed to protecting the privacy and personal data of our customers. Find out how our": "At FortyTwo, we are committed to protecting the privacy and personal data of our customers. Find out how our",
  "Privacy & Cookies Policy":"Privacy & Cookies Policy",
  "protects your data.":"protects your data.",
  "User Account": "User Account",
  "View": "View",
  "View details": "View details",
  "Vue Storefront Next": "Vue Storefront Next",
  "Who we are": "Who we are",
  "Wishlist": "Wishlist",
  "Women fashion": "Women fashion",
  "You added {product} to your shopping cart.": "You added {product} to your shopping cart.",
  "You are not authorized, please log in": "You are not authorized, please log in",
  "You can unsubscribe at any time": "You can unsubscribe at any time",
  "You currently have no orders": "You currently have no orders",
  "You haven\’t searched for items yet": "You haven’t searched for items yet.",
  "Your bag is empty": "Your bag is empty",
  "Your current email address is": "Your current email address is",
  "Your email": "Your email",
  "The email field must be a valid email":"The email field must be a valid email",
  'You have not submitted any reviews': 'You have not submitted any reviews',
  "The account sign-in was incorrect or your account is disabled temporarily. Please wait and try again later.":"The account sign-in was incorrect or your account is disabled temporarily. Please wait and try again later.",
  "A customer with the same email address already exists in an associated website.": "A customer with the same email address already exists in an associated website.",
  "Invalid email": "Invalid email",
  "SUMMER COLLECTION {year}": "SUMMER COLLECTION {year}",
  "Colorful summer dresses are already in store": "Colorful summer dresses are already in store",
  "Learn more": "Learn more",
  "Dresses": "Dresses",
  "Cocktail & Party": "Cocktail & Party",
  "Linen Dresses": "Linen Dresses",
  "T-Shirts": "T-Shirts",
  "The office life": "The office life",
  "Find stunning women's cocktail dresses and party dresses. Stand out in lace and metallic cocktail dresses from all your favorite brands.": "Find stunning women's cocktail dresses and party dresses. Stand out in lace and metallic cocktail dresses from all your favorite brands.",
  "Shop now": "Shop now",
  "The Office Life": "The Office Life",
  "Summer Sandals": "Summer Sandals",
  "Eco Sandals": "Eco Sandals",
  "Subscribe to Newsletters": "Subscribe to Newsletters",
  "Be aware of upcoming sales and events. Receive gifts and special offers!": "Be aware of upcoming sales and events. Receive gifts and special offers",
  "Fashion to take away": "Fashion to take away",
  "Download our application to your mobile": "Download our application to your mobile",
  "Share Your Look": "Share Your Look",
  "My Account": "My Account",
  "My profile": "My profile",
  "Personal Details": "Personal Details",
  "Addresses details": "Addresses details",
  "Address Details":"Address Details",
  "My newsletter": "My newsletter",
  "My Newsletter": "My Newsletter",
  "Log out": "Log out",
  "My reviews": "My reviews",
  "My Reviews": "My Reviews",
  "Order history": "Order history",
  "Order details": "Order details",
  "My wishlist": "My wishlist",
  "Change Your Password": "Change Your Password",
  "Personal Information": "Personal Information",
  "Your e-mail": "Your e-mail",
  "Current Password": "Current Password",
  "You are not authorized, please log in.": "You are not authorized, please log in.",
  "Go To Product": "Go To Product",
  "Change to list view": "Change to list view",
  "Change to grid view": "Change to grid view",
  "Returns":"Returns",
  "My orders": "My orders",
  "Add New Address": "Add New Address",
  "Set as default delivery address": "Set as default delivery address",
  "Set as default shipping address": "Set as default shipping address",
  "Set as default billing address": "Set as default billing address",
  "House/Apartment number": "House/Apartment number",
  "Street Name": "Street Name",
  "City": "City",
  "State/Province": "State/Province",
  "Zip-code": "Zip-code",
  "Country": "Country",
  "Phone number": "Phone number",
  "Please select a country first":"Please select a country first",
  "This field is required": "This field is required",
  "The field should have at least 2 characters": "The field should have at least 2 characters",
  "The field should have at least 4 characters": "The field should have at least 4 characters",
  "The field should have at least 8 characters": "The field should have at least 8 characters",
  "New Password": "New Password",
  "New Products": "New Products",
  "There are no shipping methods available for this country. We are sorry, please try with different country.": "There are no shipping methods available for this country. We are sorry, please try with different country.",
  "There was some error while trying to fetch shipping methods. We are sorry, please try with different shipping details.": "There was some error while trying to fetch shipping methods. We are sorry, please try with different shipping details.",
  "There was some error while trying to select this shipping method. We are sorry, please try with different shipping method.": "There was some error while trying to select this shipping method. We are sorry, please try with different shipping method.",
  "We can't find products matching the selection.":"We can't find products matching the selection.",
  'Page not found': 'Page not found',
  'Back to Home page': 'Back to Home page',
  'An error occurred': 'An error occurred',
  "AllProductsFromCategory": "All {categoryName}",
  "Show more": "Show more",
  "Show less": "Show less",
  "Yes": "Yes",
  "No": "No",
  "Apply filters": "Apply filters",
  "The coupon code isn't valid. Verify the code and try again.": "The coupon code isn't valid. Verify the code and try again.",
  "From": "From",
  "To": "To",
  "Your customization": "Your customization",
  "Email Address don't match":"Email Address don't match",
  "Passwords don't match":"Passwords don't match",
  "The password must be at least 8 characters long and must contain: 1 uppercase and lowercase letter, 1 number, and one special character (E.g. , . _ & ? etc)":"The password must be at least 8 characters long and must contain: 1 uppercase and lowercase letter, 1 number, and one special character (E.g. , . _ & ? etc)",
  "Show all products":"Show all products",
  "Select previously saved address":"Select previously saved address",
  "Enter an Alternate Address":"Enter an Alternate Address",
  "Enter New Address":"Enter New Address",
  "Enter Delivery Address":"Enter Delivery Address",
  "You must confirm your account. Please check your email for the confirmation link.": "You must confirm your account. Please check your email for the confirmation link.",
  "Change Store":"Change Store",
  "Choose Currency":"Choose Currency",
  "Add a review":"Add a review",
  "Add to cart":"Add to cart",
  "Title":"Title",
  "Name":"Name",
  "Review":"Review",
  "Add review":"Add review",
  "Are you sure you would like to remove this item from the shopping cart?":"Are you sure you would like to remove this item from the shopping cart?",
  "Your cart is empty":"Your cart is empty",
  "Are you sure?":"Are you sure?",
  "{0} has been successfully removed from your cart":"{0} has been successfully removed from your cart",
  "Amount":"Amount",
  "Thank you for your order!":"Thank you for your order!",
  "Your Purchase":"Your Purchase",
  "Primary contacts for any questions":"Primary contacts for any questions",
  "Your Account":"Your account",
  "What can we improve":"What can we improve",
  "Payment date":"Payment date",
  "The user password was changed successfully updated!":"The user password was changed successfully updated!",
  "The user account data was successfully updated!":"The user account data was successfully updated!",
  "You submitted your review for moderation.": "You submitted your review for moderation.",
  "Starting at": "Starting at",
  "SGD": "S$",
  //for payment method used
  "Aunthentication Error: Order record is not found.":"Aunthentication Error: Order record is not found.",
  "Aunthentication Error: Payment record is not found.":"Aunthentication Error: Payment record is not found.",
  "Payment is cancelled.":"Payment is cancelled.",
  "There was an error with the payment process.":"There was an error with the payment process.",
  "Aunthentication Error: Customer order mismatch with current order id.":"Aunthentication Error: Customer order mismatch with current order id.",
  "Please login your account before scan the QR code.":"Please login your account before scan the QR code.",
  "Cart is empty, kindly add item to cart and scan again the QR code.":"Cart is empty, kindly add item to cart and scan again the QR code.",
  "This payment link payment has been made before.":"This payment link payment has been made before.",
  "reviews":"reviews",
  "Be the first to review":"Be the first to review",
  "Year Warranty":"Year Warranty",
  "Month Warranty":"Month Warranty",
  "No Warranty":"No Warranty",
  "4 payments of S$54.75 with":"4 payments of S$54.75 with",
  "Select an option":"Select an option",
  "Add to Cart":"Add to Cart",
  "Temporarily out of stock":"Temporarily out of stock",
  "Notify me when its back":"Notify me when its back",
  "Alert subscription has been saved.":"Alert subscription has been saved.",
  "Product Description":"Product Description",
  "Product Specifications":"Product Specifications",
  "Quality Score":"Quality Score",
  "More Info Here":"More Info Here",
  "Delivery & Payment":"Delivery & Payment",
  "View More":"View More",
  "Earliest Delivery Date":"Earliest Delivery Date",
  "Order now and your item will be shipped in":"Order now and your item will be shipped in",
  "Order now and receive this item by":"Order now and receive this item by",
  "Date correct as at":"Date correct as at",
  "Your order may reach you in separate shipments as they may be packed in different/individual packages due to parcel size constraints and shipped out separately.":"Your order may reach you in separate shipments as they may be packed in different/individual packages due to parcel size constraints and shipped out separately.",
  "You will be able to select your preferred delivery date and time during Checkout. The date you select is an indication of your preferred delivery date/time. Actual date/time is directly affected by delivery schedule and route constraints.":"You will be able to select your preferred delivery date and time during Checkout. The date you select is an indication of your preferred delivery date/time. Actual date/time is directly affected by delivery schedule and route constraints.",
  "Time estimates displayed above are indicative, and will be updated according to the availability in the delivery schedule. We will be contacting you should there be any changes in your delivery date after you have made your purchase.":"Time estimates displayed above are indicative, and will be updated according to the availability in the delivery schedule. We will be contacting you should there be any changes in your delivery date after you have made your purchase.",
  "Fulfilment":"Fulfilment",
  "Fulfilled/delivered by":"Fulfilled/delivered by",
  "This item, delivered by ":"This item, delivered by ",
  ", will incur delivery charges.":", will incur delivery charges.",
  "This item will be delivered by ":"This item will be delivered by ",
  ". If your order contains":". If your order contains",
  "at least ":"at least ",
  "worth of item(s) delivered by":"worth of item(s) delivered by",
  ", all item(s) delivered by ":", all item(s) delivered by ",
  " in that order will qualify for":" in that order will qualify for",
  "FREE DELIVERY":"FREE DELIVERY",
  "This item will be delivered for FREE by ":"This item will be delivered for FREE by ",
  "Do note that this item will not affect any delivery charges corresponding to the other items in your cart (if any).":"Do note that this item will not affect any delivery charges corresponding to the other items in your cart (if any).",
  "For the final delivery charges ":"For the final delivery charges ",
  "(if any)":"(if any)",
  "of your order, please refer to your":"of your order, please refer to your",
  "Shopping Cart":"Shopping Cart",
  "\'s \"Shipping & Handling\" summary prior to checkout.":"\'s \"Shipping & Handling\" summary prior to checkout.",
  "Listed method(s) are applicable if you are only ordering this item. Available payment methods are dependent on products in Cart. Kindly place a separate order if you wish to pay using a different method.":"Listed method(s) are applicable if you are only ordering this item. Available payment methods are dependent on products in Cart. Kindly place a separate order if you wish to pay using a different method.",
  "GST Inclusive":"GST Inclusive",
  "Our already low prices are inclusive of GST!":"Our already low prices are inclusive of GST!",
  "Some retailers display product prices that are not inclusive of GST, and their display prices will be added with GST only when the items are added to cart. To make matters worse, some even charge for GST only when the item is delivered! While that\'s not exactly ethical or ":"Some retailers display product prices that are not inclusive of GST, and their display prices will be added with GST only when the items are added to cart. To make matters worse, some even charge for GST only when the item is delivered! While that\'s not exactly ethical or ",
  "legal":"legal",
  ", it also deceives customers in an attempt to fight competition.":", it also deceives customers in an attempt to fight competition.",
  "Get more bang for your buck and browse through our extensive, low-priced catalog without ever worrying about GST!":"Get more bang for your buck and browse through our extensive, low-priced catalog without ever worrying about GST!",
  "Guarantee & Policies":"Guarantee & Policies",
  "100 Day Free Returns":"100 Day Free Returns",
  "Not happy with your purchase? No problem! This item qualifies for":"Not happy with your purchase? No problem! This item qualifies for",
  "FREE return within 100 days of delivery":"FREE return within 100 days of delivery",
  "if it is new, unused, undamaged, and resalable. Request a return and get your money back—it's that easy! No questions asked.":"if it is new, unused, undamaged, and resalable. Request a return and get your money back—it's that easy! No questions asked.",
  "No Refunds or Returns on this item":"No Refunds or Returns on this item",
  "We are very keen in making our customers happy, and usually provide our customers with a 100 Day Money Back Guarantee. However, there are times where we are not able to offer the same promise due to the special nature of this product. This product is also non-refundable unless otherwise stated in the product description. To avoid disappointment, please take some time to read through the product description before making your purchase.":"We are very keen in making our customers happy, and usually provide our customers with a 100 Day Money Back Guarantee. However, there are times where we are not able to offer the same promise due to the special nature of this product. This product is also non-refundable unless otherwise stated in the product description. To avoid disappointment, please take some time to read through the product description before making your purchase.",
  "to know what products are not covered under our 100 Day Money Back Guarantee.":"to know what products are not covered under our 100 Day Money Back Guarantee.",
  "Lowest Price Matching":"Lowest Price Matching",
  "Bought our products and found they are selling cheaper elsewhere? We\'ll match that price and refund you the difference within 14 days of your purchase!":"Bought our products and found they are selling cheaper elsewhere? We\'ll match that price and refund you the difference within 14 days of your purchase!",
  "Got a Lemon?":"Got a Lemon?",
  "Don't worry, we've got you covered! Our flexible business practices go beyond the protections of the":"Don't worry, we've got you covered! Our flexible business practices go beyond the protections of the",
  "Lemon Law":"Lemon Law",
  "Let us know your feedback, and we'll do our best to help!":"Let us know your feedback, and we'll do our best to help!",
  "Right to Reject":"Right to Reject",
  "All qualifying purchases on FortyTwo are protected by our \"Right to Reject\" policy. If your product arrives in unsatisfactory condition or doesn't meet your expectations, you can reject it at no added cost.":"All qualifying purchases on FortyTwo are protected by our \"Right to Reject\" policy. If your product arrives in unsatisfactory condition or doesn't meet your expectations, you can reject it at no added cost.",
  "For more details about our Right to Reject policy,":"For more details about our Right to Reject policy,",
  "click here.":"click here.",
  "Ask a Question":"Ask a Question",
  "Write in and let us know if you have any questions about this product!":"Write in and let us know if you have any questions about this product!",
  "You must be":"You must be",
  "logged in":"logged in",
  "to ask questions.":"to ask questions.",
  "Question":"Question",
  "Please...":"Please...",
  "Send Question":"Send Question",
  "Your question has been received. Please allow 1-2 working day to receive a reply.":"Your question has been received. Please allow 1-2 working day to receive a reply.",
  "Customers\' Reviews":"Customers\' Reviews",
  "Based on":"Based on",
  "Reviews":"Reviews",
  "Be the First to Review!":"Be the First to Review!",
  "Join thousands of satisfied customers who trust us and feel confident in your purchase. Experience the exceptional features and quality of this product or explore our trusted quality score ":"Join thousands of satisfied customers who trust us and feel confident in your purchase. Experience the exceptional features and quality of this product or explore our trusted quality score ",
  "here":"here",
  "By":"By",
  "on":"on",
  "View all Reviews":"View all Reviews",
  "Ships in":"Ships in",
  "Business Days":"Business Days",
  "FREE delivery by":"FREE delivery by",
  "Earliest by":"Earliest by",
  "FREE standard delivery of orders over S$300":"FREE standard delivery of orders over S$300",
  "Want it by":"Want it by",
  "Choose 42 EXPRESS when checking out.":"Choose 42 EXPRESS when checking out.",
  "Express Delivery Currently Unavailable":"Express Delivery Currently Unavailable",
  "Our trucks are busy beautifying homes. Be back soon!":"Our trucks are busy beautifying homes. Be back soon!",
  "Select your product options to view the earliest delivery date.":"Select your product options to view the earliest delivery date.",
  "was added to your cart":"was added to your cart",
  "was removed from your cart":"was removed from your cart",
  "Continue to cart":"Continue to cart",
  "Recommended Pairings":"Recommended Pairings",
  "Sold Out":"Sold Out",
  "More {colour} options":"More {colour} options",
  "FortyTwo #IRL":"FortyTwo #IRL",
  "Our customers say it best: We're the difference they've been looking for. #fortytwosg":"Our customers say it best: We're the difference they've been looking for. #fortytwosg",
  "fortytwosg":"fortytwosg",
  // Footer
  "Be the first to know our latest promotions & deals!":"Be the first to know our latest promotions & deals!",
  "Loading...":"Loading...",
  "Thank you for your subscription.":"Thank you for your subscription.",
  "Invalid email format":"Invalid email format",
  "We are 100% online!":"We are 100% online!",
  "No showrooms, giving you greater savings!":"No showrooms, giving you greater savings!",
  "FortyTwo is the largest homegrown e-furniture site in Singapore. With over 30,000 items across furniture and accessories including sofa sets, bed frames, mattresses and rugs, FortyTwo is your go-to platform for all things furniture and more!":"FortyTwo is the largest homegrown e-furniture site in Singapore. With over 30,000 items across furniture and accessories including sofa sets, bed frames, mattresses and rugs, FortyTwo is your go-to platform for all things furniture and more!",
  "About Us":"About Us",
  "About FortyTwo":"About FortyTwo",
  "Contact Us":"Contact Us",
  "Need Help?":"Need Help?",
  "Orders & Returns":"Orders & Returns",
  "100 Days Free Returns":"100 Days Free Returns",
  "FAQ":"FAQ",
  "Hotline Hours: Mon - Fri,":"Hotline Hours: Mon - Fri,",
  "10am - 6pm (except PH)":"10am - 6pm (except PH)",
  "Showroom":"Showroom",
  "Find Out More":"Find Out More",
  "© ":"© ",
  " FortyTwo Pte. Ltd. All Rights Reserved.":" FortyTwo Pte. Ltd. All Rights Reserved.",
  "Terms of Service":"Terms of Service",
  "Privacy Policy":"Privacy Policy",
  "Results": "Results",
  "Filters and sort": "Filters and sort",
  "Hide Filters": "Hide Filters",
  "Show Filters":"Show Filters",
  // Header
  "Search for furniture, mattresses, décor & more":"Search for furniture, mattresses, décor & more",
  "Metaroom":"Metaroom",
  "Singapore":"Singapore",
  "HELP":"HELP",
  "Help Center":"Help Center",
  "Delivery FAQs":"Delivery FAQs",
  "FAVORITES":"FAVORITES",
  // Reset Password
  "There was an error with the password reset process.":"There was an error with the password reset process.",
  "Your password has been successfully reset. Please login again with your new password." : "Your password has been successfully reset. Please login again with your new password.",

  // Delivery Module
  "Delivery Info" : "Delivery Info",
  "Delivery Charge": "Delivery Charge", 
  "Today": "Today", 
  "Tomorrow": "Tomorrow", 
  "Total Delivery Charge": "Total Delivery Charge", 
  "Item Total":"Item Total",
  "Preferred Delivery Time": "Preferred Delivery Time",
  "Preferred Delivery Date": "Preferred Delivery Date",
  "Request for Free Mattress Disposal?":"Request for Free Mattress Disposal?",
  "To facilitate old mattress disposals":"To facilitate old mattress disposals, we need to allocate additional space in our delivery vehicle before sending over your order. Hence, please check the box above if you would like us to dispose of your old mattress. Do note that we will be unable to dispose of your old mattress(es) if you have not indicated your request here prior to your FortyTwo delivery. Your understanding on this matter is greatly appreciated.",
  "Free disposal services":"Free disposal services are currently limited to old mattresses with the purchase of a new mattress only, up to the quantity of new mattresses you have bought. Should you wish to dispose of other furniture items, our bulky item disposal service is chargeable and must be included in your order when you make a purchase.",
  "Click here to find out more about our bulky item disposal service.":"Click here to find out more about our bulky item disposal service.",
  "Alternatively, you may contact your Town Council":"Alternatively, you may contact your Town Council for their free bulky item disposal service. For private properties, please get in touch with your estate/building management for assistance.",
  "Click here for a list of links and hotlines for your Town Council’s bulky item disposal service.":"Click here for a list of links and hotlines for your Town Council’s bulky item disposal service.",
  "FortyTwo Pte. Ltd reserves the right":"FortyTwo Pte. Ltd reserves the right at its full discretion to refuse disposal of any old mattresses, including mattresses that are infested (or display signs of infestation) by bed bugs, insects, or other pests.",
  "Special Remarks":"Special Remarks",
  "Select a Timeslot": "Select a Timeslot",
  "Select a Date": "Select a Date",
  "Delivery Date": "Delivery Date",
  "Free Delivery": "Free Delivery",
  "Standard": "Standard",
  "Express": "Express",
  "Postal":"Postal",
  "Select Preferred Delivery Date first": "Select Preferred Delivery Date first",
  "The items listed below will be delivered by": "The items listed below will be delivered by",
  "Delivery Method": "Delivery Method",
  "Continue to delivery info": "Continue to delivery info", 
  "Timing Surcharge": "Timing Surcharge", 
  "Location Surcharge": "Location Surcharge", 
  "Error, please select all delivery date and timeslot!": "Error, please select all delivery date and timeslot!", 
  "Invalid Delivery Date / Timeslot": "Invalid Delivery Date / Timeslot", 
  "Please select another delivery date and timeslot. If you continue receiving this error, please refresh this page.": "Please select another delivery date and timeslot. If you continue receiving this error, please refresh this page.",
  "Error, please select all delivery date and timeslot!": "Error, please select all delivery date and timeslot!",
  "Error": "Error",
  "E-mail": "E-mail",
  // Checkout Process
  "Your Details":"Your Details",
  "Delivery":"Delivery",
  "SSL ENCRYPTION":"SSL ENCRYPTION",
  "Your Cart":"Your Cart",
  "Fulfilled by":"Fulfilled by",
  "Order Summary":"Order Summary",
  "Add Coupon Code":"Add Coupon Code",
  "Remove Coupon Code":"Remove Coupon Code",
  "Delivery surcharges may apply to to certain locations.":"Delivery surcharges may apply to to certain locations.",
  "If your premises are not lift-accessible or items are too large for the lift,":"If your premises are not lift-accessible or items are too large for the lift,",
  "additional delivery charges":"additional delivery charges",
  "may apply.":"may apply.",
  "100 days free return available for most products. Find out more about our":"100 days free return available for most products. Find out more about our",
  "Returns & Refund Policy":"Returns & Refund Policy",
  "What is your email address?":"What is your email address?",
  "Enter your email to get started":"Enter your email to get started",
  "Login & Checkout":"Login & Checkout",
  "Get started with checkout by creating an account":"Get started with checkout by creating an account",
  "Email":"Email",
  "Next":"Next",
  "Make Payment":"Make Payment",
  "Create account":"Create account",
  "Confirm Password":"Confirm Password",
  "Delivery Address Information":"Delivery Address Information",
  "Billing Address Information":"Billing Address Information",
  "Company (optional)":"Company (optional)",
  "Building Name":"Building Name",
  "Street Address":"Street Address",
  "Block / Lobby":"Block / Lobby",
  "Unit / Apt No.":"Unit / Apt No.",
  "Postal Code":"Postal Code",
  "Telephone No.":"Telephone No.",
  "Alternate Telephone No.":"Alternate Telephone No.",
  "Not required":"Not required",
  "Not applicable":"Not applicable",
  "Please select your address":"Please select your address",
  "Confirm":"Confirm",
  "We have detected that you may have entered an invalid postal code.":"We have detected that you may have entered an invalid postal code.",
  "Please ignore this message if you are sure it's accurate.":"Please ignore this message if you are sure it's accurate.",
  "The field should have at least 6 characters":"The field should have at least 6 characters",
  "This field should have 6 characters":"This field should have 6 characters",
  "This field should have 2 characters":"This field should have 2 characters",
  "This field should have 4 characters":"This field should have 4 characters",
  "This field should have 8 characters":"This field should have 8 characters",
  "* Please note that preferred delivery times may be subjected to change.":"* Please note that preferred delivery times may be subjected to change.",
  "For items fulfilled by FortyTwo Pte Ltd, the confirmed delivery timing will be sent via SMS 1 working day before delivery.":"For items fulfilled by FortyTwo Pte Ltd, the confirmed delivery timing will be sent via SMS 1 working day before delivery.",
  "Click here":"Click here",
  "for more details.":"for more details.",
  "Choose your preferred delivery date and time":"Choose your preferred delivery date and time",
  "View Items":"View Items",
  "(Currently Unavailable)":"(Currently Unavailable)",
  "Select a Time":"Select a Time",
  "Please select a Preferred Delivery Date first":"Please select a Preferred Delivery Date first",
  "FULL":"FULL",
  "Free":"Free",
  "Please indicate any comments you will like to add for this delivery here.":"Please indicate any comments you will like to add for this delivery here.",
  "Unfortunately, {delivery_group_name} is unable to deliver to your specified address.":"Unfortunately, {delivery_group_name} is unable to deliver to your specified address.",
  "Please enter another address in the previous step.":"Please enter another address in the previous step.",
  "* We have detected that your address is a private property (eg. Condo). Some delivery dates or timeslots are unavailable for your address as your property management is likely to decline access to us to perform the delivery at those times.":"* We have detected that your address is a private property (eg. Condo). Some delivery dates or timeslots are unavailable for your address as your property management is likely to decline access to us to perform the delivery at those times.",
  "How would you like to pay":"How would you like to pay",
  "Back To Cart":"Back To Cart",
  "Back to Login":"Back to Login",
  "Thank you for shopping with us!":"Thank you for shopping with us!",
  "Your order confirmation and order details have been sent to your email address.":"Your order confirmation and order details have been sent to your email address.",
  "Order Number":"Order Number",
  "Order Date":"Order Date",
  "Continue Shopping":"Continue Shopping",
  "View Order":"View Order",
  "Shipping Address":"Shipping Address",
  "Delivery Address":"Delivery Address",
  "Billing Address":"Billing Address",
  "items":"items",
  "item":"item",
  "Grand Total":"Grand Total",
  "GST":"GST",
  "inclusive of GST":"inclusive of GST",
  // Checkout V1
  "Already Registered?":"Already Registered?",
  "If you have an account with us, please log in.":"If you have an account with us, please log in.",
  "Forgot your password?":"Forgot your password?",
  "New Here?":"New Here?",
  "Registration is free and easy!":"Registration is free and easy!",
  "Proceed to Checkout":"Proceed to Checkout",
  "Billing Information":"Billing Information",
  "Delivery Date/Time":"Delivery Date/Time",
  "Delivery Date / Time":"Delivery Date / Time",
  "Payment Information":"Payment Information",
  "Order Review":'Order Review',
  "* Required Fields":"* Required Fields",
  "Continue":"Continue",
  "Select a delivery address from your address book or enter a new address.":"Select a delivery address from your address book or enter a new address.",
  "Select a billing address from your address book or enter a new address.":"Select a billing address from your address book or enter a new address.",
  "Enter your postal code to get your address.":"Enter your postal code to get your address.",
  "New Address":"New Address",
  "Delivery Charges":"Delivery Charges",
  "Free Standard Delivery":"Free Standard Delivery",
  "We have detected that your address is a private property (eg. Condo). Some delivery dates or timeslots are unavailable for your address as your property management is likely to decline access to us to perform the delivery at those times.":"We have detected that your address is a private property (eg. Condo). Some delivery dates or timeslots are unavailable for your address as your property management is likely to decline access to us to perform the delivery at those times.",
  "The dates available for selection above are based on the earliest date when all the products in this delivery group becomes available. If you wish to receive some items at an earlier date, please place a separate order for those items to receive them in separate deliveries.":"The dates available for selection above are based on the earliest date when all the products in this delivery group becomes available. If you wish to receive some items at an earlier date, please place a separate order for those items to receive them in separate deliveries.",
  "*Please note that the Preferred Delivery Time that you have selected is only an indication of the time range that you prefer to receive the delivery. A confirmed delivery timing will be sent to you via SMS 1 working day before the day of delivery. The SMS notification will only be sent for items delivered by FortyTwo Delivery only.":"*Please note that the Preferred Delivery Time that you have selected is only an indication of the time range that you prefer to receive the delivery. A confirmed delivery timing will be sent to you via SMS 1 working day before the day of delivery. The SMS notification will only be sent for items delivered by FortyTwo Delivery only.",
  "Read our Help Centre article":"Read our Help Centre article",
  "for details.":"for details.",
  "Some delivery locations carries a surcharge.":"Some delivery locations carries a surcharge.",
  "Please note: Should your premises be inaccessible through the lift, or if the items that you order are deemed too large to fit into the lift connecting to the floor where your premises are located, additional charges will apply. Please":"Please note: Should your premises be inaccessible through the lift, or if the items that you order are deemed too large to fit into the lift connecting to the floor where your premises are located, additional charges will apply. Please",
  "read our Help Centre article":"read our Help Centre article",
  "to find out how you may be charged.":"to find out how you may be charged.",
  "*Please note: Should your premises be inaccessible through the lift, or if the items that you order are deemed too large to fit into the lift connecting to the floor where your premises are located, additional charges will apply.":"*Please note: Should your premises be inaccessible through the lift, or if the items that you order are deemed too large to fit into the lift connecting to the floor where your premises are located, additional charges will apply.",
  "Please select how you will like to pay:":"Please select how you will like to pay:",
  "Send me promotions, discounts & updates via email":"Send me promotions, discounts & updates via email",
  "Send Me Promotions, Discounts & Updates":"Send Me Promotions, Discounts & Updates",
  "Place Order":"Place Order",
  // Cart Page
  "Your Shopping Cart":"Your Shopping Cart",
  "Check Out":"Check Out",
  "to free delivery":"to free delivery",
  "delivery charge":"delivery charge",
  "You get FREE Shipping":"You get FREE Shipping",
  "Unit Price":"Unit Price",
  "Qty":"Qty",
  "Row Total":"Row Total",
  "Edit selections":"Edit selections",
  "Empty Cart":"Empty Cart",
  "View Shopping Cart":"View Shopping Cart",
  "Are you sure you would like to empty the shopping cart?":"Are you sure you would like to empty the shopping cart?",
  "All Products has been successfully removed from your cart":"All Products has been successfully removed from your cart",
  "Warranty":"Warranty",
  "Your shopping cart is currently empty.":"Your shopping cart is currently empty.",
  "Start shopping now!":"Start shopping now!",
  // Review Page
  "Write a Review":"Write a Review",
  "Order":"Order",
  "Review items you have ordered and stand a chance to win 2 x Magickoil Prime Latex Pillow (worth S$176) in our monthly lucky draw!":"Review items you have ordered and stand a chance to win 2 x Magickoil Prime Latex Pillow (worth S$176) in our monthly lucky draw!",
  "Bought":"Bought",
  "at":"at",
  "Review the product to help others.":"Review the product to help others.",
  "Ratings & Reviews":"Ratings & Reviews",
  "Back to Main Product Info":"Back to Main Product Info",
  "Product not found":"Product not found",
  // My Profile
  "Update Your Address":"Update Your Address",
  "Update Address":"Update Address",
  // My Account
  "Order #":"Order #",
  "Ship To":"Ship To",
  "My Profile": "My Profile",
  "My Product Reviews":"My Product Reviews",
  "My Wishlist": "My Wishlist",
  "My Addresses":"My Addresses",
  "View Details":"View Details",
  "Back to My Orders":"Back to My Orders",
  "My Orders":"My Orders",
  "Newsletter Subscriptions":"Newsletter Subscriptions",
  "Subscription":"Subscription",
  // My Wishlist
  "View Wishlist":"View Wishlist",
  "There are no items in your Wishlist.":"There are no items in your Wishlist.",
  "Select the":"Select the",
  "button on the product page.":"button on the product page.",
  "Browse Now":"Browse Now",
  // Login
  "Login to save your favourite items, and view your order status.":"Login to save your favourite items, and view your order status.",
  "Create an Account":"Create an Account",
  "Create an account to get notified on the latest products, save your favourite items, and view your orders.":"Create an account to get notified on the latest products, save your favourite items, and view your orders.",
  "Back":"Back",
  "By clicking on “Create account” I confirm that I have read and":"By clicking on “Create account” I confirm that I have read and",
  "agree to Fortytwo’s":"agree to Fortytwo’s",
  "Terms & Conditions":"Terms & Conditions",
  "and":"and",
  //single order
  "You can check your order by logging into your account.": "You can check your order by logging into your account.",
  "Sub total": "Sub total",
  //payment redirect loader
  "Redirecting you to the payment gateway. Please wait...": "Redirecting you to the payment gateway. Please wait...",
  "Redirecting you back to {storeURL}. Please wait...": "Redirecting you back to {storeURL}. Please wait...",
  "{product} was added to your cart." : "{product} was added to your cart.",
  "Order Now, Deliver Today":"Order Now, Deliver Today",
  "Order Now, Deliver Tomorrow":"Order Now, Deliver Tomorrow",
  "Deliver from":"Deliver from",
  "Deliver in":"Deliver in",
  "Days":"Days",
  //sorting filter
  "Best Value: Best First" : "Best Value: Best First",
  "Price: Low to High" : "Price: Low to High",
  "Price: High to Low" : "Price: High to Low",
  "Delivery: Fastest first" : "Delivery: Fastest first",
  "New: Newest first" : "New: Newest first",
  "Best Seller: Best first" : "Best Seller: Best first",
  "Top Rated: High to Low" : "Top Rated: High to Low",
  "Reviews Count: High to Low" : "Reviews Count: High to Low",
  "Width (cm): {width}, Height (cm): {height}, Depth (cm): {depth}":"Width (cm): {width}, Height (cm): {height}, Depth (cm): {depth}",
  "Oh no, we have a problem here! Remember to log in to add this item to your wishlist.":"Oh no, we have a problem here! Remember to log in to add this item to your wishlist.",
  "Item removed from wishlist successfully.":"Item removed from wishlist successfully.",
  "Item added to wishlist successfully.":"Item added to wishlist successfully.",
  //payment error message
  'There has been an error with your payment transaction and the transaction has been cancelled. Please try again later. If the issue persists, you may contact us at cs@fortytwo.sg for assistance. In your email, please indicate the error code "<gateway error code>" and the email address you used to place the order. We are sorry for any inconvenience caused.' : 'There has been an error with your payment transaction and the transaction has been cancelled. Please try again later. If the issue persists, you may contact us at cs@fortytwo.sg for assistance. In your email, please indicate the error code "<gateway error code>" and the email address you used to place the order. We are sorry for any inconvenience caused.',
  "The transaction has been successfully cancelled at your request." : "The transaction has been successfully cancelled at your request.",
  "You must create an account or login to checkout and make payment." : "You must create an account or login to checkout and make payment.",
  "The payment QR code has expired. Please approach our staff for assistance." : "The payment QR code has expired. Please approach our staff for assistance.",
  "Please add the items you wish to purchase in your shopping cart before scanning the payment QR code." : "Please add the items you wish to purchase in your shopping cart before scanning the payment QR code.",
  "This payment link has expired. Please contact us if you require a new payment link." : "This payment link has expired. Please contact us if you require a new payment link.",
  "There has been an error with your payment transaction." : 'There has been an error with your payment transaction and the transaction has been cancelled. Please try again later. If the issue persists, you may contact us at cs@fortytwo.sg for assistance. In your email, please indicate the error code "<gateway error code>" and the email address you used to place the order. We are sorry for any inconvenience caused.',
  "Please specify payment method.":"Please specify payment method.",
  //category filter
  "Fast Delivery":"Fast Delivery",
  "Reset Filters":"Reset Filters",
  "Show More": "Show More",
  "Show Less": "Show Less",
  "We're sorry! This category is still under development!":"We're sorry! This category is still under development!",
  "Exciting new options are on the way! In the meantime, do explore our other categories for more choices.":"Exciting new options are on the way! In the meantime, do explore our other categories for more choices.",
};
