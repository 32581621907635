import type { MetaInfo } from 'vue-meta';
import { useConfigStore } from '~/stores/config';
import { Logger } from '~/helpers/logger';

export const getMetaInfo = (page: any, isNoIndex: boolean = false): MetaInfo => {
  const { storeConfig } = useConfigStore();
  const title_suffix = " | FortyTwo";
  const seoTags: MetaInfo = {
    meta: [],
  };

  if (page?.meta_title || page?.title || page?.name || storeConfig?.default_title) {
    seoTags.title = page?.meta_title || page?.title || page?.name || storeConfig?.default_title;
  }

  // use product name as title for product page
  if (page?.__typename && page?.__typename.indexOf("Product") !== -1) {
    seoTags.title = page?.name;
  }
  
  const og_title = `${(storeConfig.title_prefix ? storeConfig.title_prefix + ' ' : '')}${page?.og_title ?? seoTags.title}${title_suffix}`
  Logger.debug('seoTags.title', seoTags.title);

  seoTags.meta.push({
    hid: 'og:title',
    name: 'og:title',
    content: og_title,
  });

  seoTags.title = (storeConfig.title_prefix ? storeConfig.title_prefix + ' ' : '') + seoTags.title + (storeConfig.title_suffix ? ' ' + storeConfig.title_suffix : '');

  const getUrl = (url) => {
    const image_suffix = "s=og";
    // default image url if url param is not provided
    if (!url){
      return `${storeConfig?.base_media_url}logo/image/FortyTwo-Logo_1200x630.gif?${image_suffix}`
    }
    const lastIndex = url.lastIndexOf("s=image");
    // replace "s=image" with "s=og"
    if (lastIndex !== -1) {
        return url.substring(0, lastIndex) + image_suffix;
    } 
    // append image_suffix
    return `${url}?${image_suffix}`;
  }

  seoTags.meta.push({
    hid: 'og:image',
    name: 'og:image',
    content: getUrl(page?.image?.url),
  });

  seoTags.meta.push({
    hid: 'apple-mobile-web-app-title',
    name: 'apple-mobile-web-app-title',
    content: 'FortyTwo',
  });

  seoTags.meta.push({
    hid: 'og:site_name',
    name: 'og:site_name',
    content: 'FortyTwo',
  });

  // seoTags.title = (storeConfig.title_prefix ? storeConfig.title_prefix + ' ' : '') + seoTags.title + (storeConfig.title_suffix ? ' ' + storeConfig.title_suffix : '');
  if (page?.og_description || page?.meta_description || storeConfig.default_description) {
    seoTags.meta.push({
      hid: 'description',
      name: 'description',
      content: page?.meta_description || storeConfig.default_description,
    });

    seoTags.meta.push({
      hid: 'og:description',
      name: 'og:description',
      content: page?.og_description || page?.meta_description || page?.short_description?.html || storeConfig.default_description,
    });
  }
  if (page?.meta_keyword || page?.meta_keywords || storeConfig.default_keywords) {
    seoTags.meta.push({
      hid: 'keywords',
      name: 'keywords',
      content: page?.meta_keyword || page?.meta_keywords || storeConfig.default_keywords,
    });

    seoTags.meta.push({
      hid: 'og:keywords',
      name: 'og:keywords',
      content: page?.meta_keyword || page?.meta_keywords || storeConfig.default_keywords,
    });
  }
  if (isNoIndex) {
    seoTags.meta.push({
      name: 'robots',
      content: 'noindex, nofollow',
    });
  }

  return seoTags;
};
