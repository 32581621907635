import { useApi } from '~/composables/useApi';
import axios from "axios";
import { useContext } from '@nuxtjs/composition-api';
import useFtGenerateFacebookPixelEventId from '~/composables/FortyTwo/useFtGenerateFacebookPixelEventId';

export const useFtSendFacebookPixel = () => {
  const { query } = useApi();
  const { $cookies } = useContext();
  const nuxtContext:any = useContext();
  const { generateFacebookPixelEventId } = useFtGenerateFacebookPixelEventId();
  
  const sendFacebookPixel:any = async (eventName, paramEntityId, custom_data: any) =>{
    const clientdata = await axios.get('/client-header');
    const clientIpAddress = clientdata.data.clientipaddress;
    const clientUserAgent = clientdata.data.clientuseragent;
    const fbp = $cookies.get('_fbp') ?? '';
    const entityID = paramEntityId ? paramEntityId : window.location.href;
    let entityType = 'product';
    let itemInfo = {};
    let pixelParameter = {};

    if (eventName === 'PageView') {
      entityType = 'cms';
      
    } else if (eventName === 'ViewCategory') {
      entityType = 'category';
      itemInfo = {
        'name': custom_data
      }

    } else if (eventName === 'Search') {
      entityType = 'search';
      itemInfo = {
        'search_string': custom_data ? custom_data.substring(0, 15) : ''
      }

    } else if (eventName === 'CompleteRegistration') {
      entityType = 'customer';

    } else if (eventName === 'InitiateCheckout') {
      entityType = 'quote';
      const itemList = [];
      custom_data.items.forEach((item) => {
        itemList.push({
          'id': item.product.__typename == 'SimpleProduct' ? item.product.msbc_item_no : item.product.sku,
          'quantity': item.quantity,
          'item_price': item.prices.price_including_tax.value
        })
      });
      itemInfo = {
        'items': itemList,
        'num_items': custom_data.total_quantity,
        'value': custom_data.prices.grand_total.value
      }

    } else if (eventName === 'Purchase') {
      entityType = 'order';
      const totalQty = custom_data.items.reduce((acc, curr)=> acc + curr.quantity_ordered , 0);
      const itemList = [];
      custom_data.items.forEach((item) => {
        itemList.push({
          'id': item.product_type == 'simple' ? item.product_msbc_item_no : item.product_sku,
          'quantity': item.quantity_ordered,
          'item_price': item.quantity_ordered > 1 ? (item.product_sale_price.value/item.quantity_ordered) : item.product_sale_price.value
        })
      });
      itemInfo = {
        'order_id': custom_data.number,
        'items': itemList,
        'num_items': totalQty,
        'value': custom_data.grand_total
      }

      pixelParameter = {
        'value': custom_data.total.grand_total.value,
        'currency': custom_data.total.grand_total.currency
      }

    } else if (eventName === 'ViewContent' || eventName === 'AddToCart') {
      itemInfo = custom_data;
    }

    const facebookPixelEventId = await generateFacebookPixelEventId(eventName, entityType, entityID, fbp, clientIpAddress, clientUserAgent);
    
    const facebookEventInfo: any = {
      'event_name': eventName,
      'event_id': facebookPixelEventId.data.generateFacebookPixelEventId.eventId,
      'event_source_url': window.location.href,
      'fbp': fbp,
      'client_ip_address': clientIpAddress,
      'client_user_agent': clientUserAgent,
      'item_info': itemInfo
    };

    const encodeFacebookEventInfo = Buffer.from(JSON.stringify(facebookEventInfo)).toString('base64');

    const myGraphqlQuery = `
    query sendFacebookPixel($facebook_event_info: String){
      sendFacebookPixel(facebook_event_info: $facebook_event_info){
        error
        message
      }
    }
    `;

    if (eventName === 'ViewCategory') {
      nuxtContext.$fb.query('trackCustom', eventName, pixelParameter, {eventID: facebookPixelEventId.data.generateFacebookPixelEventId.eventId});
    } else {
      nuxtContext.$fb.track(eventName, pixelParameter, {eventID: facebookPixelEventId.data.generateFacebookPixelEventId.eventId});
    }
    query(myGraphqlQuery, {facebook_event_info: encodeFacebookEventInfo});
  }
  
  return {
    sendFacebookPixel,
  }
}


export default useFtSendFacebookPixel;