








































































import { defineComponent, ref, useFetch, onMounted, onUnmounted, useContext } from '@nuxtjs/composition-api';
import { SfIcon, SfOverlay, SfHeader } from '@storefront-ui/vue';
import type { CategoryTree, ProductInterface } from '~/modules/GraphQL/types';
import { useCategory } from '~/modules/catalog/category/composables/useCategory';
import HeaderTop from "~/components/FortyTwo/header/HeaderTop.vue";
import HeaderIcon from "~/components/FortyTwo/header/HeaderIcon.vue";
import HeaderTopGA4 from "~/components/FortyTwo/header/HeaderTopGA4.vue";
import HeaderNavigation from '~/components/FortyTwo/header/Navigation/HeaderNavigation.vue';
import HeaderNavigationCustom from '~/components/FortyTwo/header/NavigationCustom/HeaderNavigationCustom.vue';
import StickyHeaderNavigationCustom from '~/components/FortyTwo/header/NavigationCustom/StickyHeaderNavigationCustom.vue';
import useFtGetMenuInfo from '~/composables/FortyTwo/useFtGetMenuInfo';
import { merge, debounce } from 'lodash-es';
import { useUser } from '~/modules/customer/composables/useUser';
import { useCart } from '~/modules/checkout/composables/useCart';
import { useWishlist } from '~/modules/wishlist/composables/useWishlist';
import { useCustomerStore } from '~/modules/customer/stores/customer';
import useFTGetCustomerGroupId from '~/modules/fortytwo/customer/composables/useFTGetCustomerGroupId';
import useFtGetCartTotal from '~/composables/FortyTwo/useFtGetCartTotal';
import { useCartStore } from '~/modules/checkout/stores/cart';

export default defineComponent({
    name: 'FortytwoAppHeader',
    components: {
        SfIcon,
        SfOverlay,
        SfHeader,
        HeaderTop,
        HeaderIcon,
        HeaderTopGA4,
        HeaderNavigation,
        HeaderNavigationCustom,
        StickyHeaderNavigationCustom,
        SearchBar: () => import('~/components/FortyTwo/header/SearchBar/SearchBar.vue'),
        SearchResults: () => import('~/components/FortyTwo/header/SearchBar/SearchResults.vue'),
    },
    setup() {
        const { app } = useContext();
        const { user, load: loadUser } = useUser();
        const { loadTotalQty: loadCartTotalQty, cart, load: loadCart } = useCart();
        const { loadItemsCount: loadWishlistItemsCount } = useWishlist();
        const { getMenuInfo } = useFtGetMenuInfo();
        const { cartTotalAndTotalQty } = useFtGetCartTotal();
        const cartStore = useCartStore();
        const apiState = app.$vsf.$magento.config.state;
        const isSearchOpen = ref(false);
        const isMobileSearchOpen = ref(false);
        const productSearchResults = ref<ProductInterface[] | null>(null);
        const menuInfo = ref(null);
        const fetchMenuInfoData = async () => {
            const { data } = await getMenuInfo();
            menuInfo.value = merge({}, menuInfo.value, data.menuInfor ?? null);
        };

        const fetchCartTotalAndTotalQty = async (cartId) => {
            const { data } = await cartTotalAndTotalQty(cartId);
            if(data?.cart) {
                cartStore.$patch((state) => {
                    state.cart = merge({}, state.cart, data.cart);
                });
            }
        };
        
        // categoryTree: menu list
        const categoryTree = ref<CategoryTree[]>([]);
        const { categories: categoryList, load: categoriesListLoad } = useCategory();
        useFetch(async () => {
            await categoriesListLoad({ pageSize: 20 });
            categoryTree.value = categoryList.value?.[0]?.children.filter((category) => category.include_in_menu);
            await fetchMenuInfoData();
        });

        const customerStore = useCustomerStore();
        const { getCustomerGroupId } = useFTGetCustomerGroupId();  
        const customerGroupId= ref(0);
        const fetchCustomerGroupId= async () => {
            const { data } = await getCustomerGroupId();
            customerGroupId.value = data?.getCustomerGroupId?.cust_grp_id ?? 0;
            await customerStore.setCustomerGroupId(customerGroupId.value);
        };

        const isScrollVisible = ref(false);
        const handleScroll = () =>{
            if (window.scrollY > 10){
                isScrollVisible.value = true;
            }
            else{
                isScrollVisible.value = false;
            }
        }
        const delayedHandleScroll = debounce(() =>{
            if (window.scrollY > 500){
                isScrollVisible.value = true;
            }
            else{
                isScrollVisible.value = false;
            }
        }, 500);

        onMounted(async () => {
            if (user.value === null) {
                await loadUser();
            }
            if(user?.value?.email) {
                await fetchCustomerGroupId();
            }
            if (apiState.getCartId()) {
                await fetchCartTotalAndTotalQty(apiState.getCartId());
            }
            if (app.$device.isDesktop) {
            window.addEventListener("scroll", handleScroll);
            }
        });

        onUnmounted(() => {
            if (app.$device.isDesktop) {
            window.removeEventListener("scroll", handleScroll);
            }
        });

        return {
            isMobileSearchOpen,
            isSearchOpen,
            productSearchResults,
            categoryTree,
            menuInfo,
            handleScroll,
            isScrollVisible,
        };
    }
})
