import { useApi } from '~/composables/useApi';
import axios from "axios";
import { useContext } from '@nuxtjs/composition-api';

export const useFtDynamicYieldEvent = () => {
    const { query } = useApi();
    const { $cookies } = useContext();
    const sendDYEvent = async (eventType:any, paramData:any, paramData1:any) =>{
        try{
            const clientdata = await axios.get('/client-header');
            const clientIpAddress = clientdata.data.clientipaddress;
            const encodedClientIpAddress = window.btoa(clientIpAddress) ?? '';
            const dyid_servers = window.btoa($cookies.get('_dyid_server')) ?? '';
            const dyid_data = window.btoa($cookies.get('_dyid')) ?? '';
            const dyid_sessions = window.btoa($cookies.get('_dyjsession')) ?? '';
            const myGraphqlQuery = `
            query dyeventinit
                (
                $event_type: String!,
                $dyid_data: String!,
                $dyid_server: String!,
                $dyid_session: String!,
                $dyid_ipdata: String!,
                $data: String!,
                $data1: String!
                ){
                    dyeventinit(
                    event_type: $event_type,
                    dyid_ipdata: $dyid_ipdata, 
                    dyid_data: $dyid_data, 
                    dyid_server: $dyid_server,
                    dyid_session: $dyid_session, 
                    data: $data, 
                    data1: $data1
                    ) 
                        {
                            event_type
                        }
                }
            `;
            const variable = {
                event_type: eventType,
                dyid_data: dyid_data,
                dyid_ipdata: encodedClientIpAddress,
                dyid_server: dyid_servers,
                dyid_session: dyid_sessions,
                data: paramData,
                data1: paramData1
            };
            if(clientIpAddress && dyid_servers){ query(myGraphqlQuery, variable); }   
        }catch(error){
            console.error(error);
        }
    }
    return sendDYEvent;
}

export default useFtDynamicYieldEvent;