































































import {
  ref,
  defineComponent,
  computed,
  useContext,
  useRouter, 
  onMounted
} from '@nuxtjs/composition-api';
import {
  SfModal,
  SfBar,
} from '@storefront-ui/vue';
import { useUiState } from '~/composables/useUiState';
import { useCart } from '~/modules/checkout/composables/useCart';
import { useForgotPassword } from '~/modules/customer/composables/useForgotPassword';
import { useUser } from '~/modules/customer/composables/useUser';

import LoginForm from '~/modules/fortytwo/customer/components/LoginModal/forms/LoginForm.vue';
import RegisterForm from '~/modules/fortytwo/customer/components/LoginModal/forms/RegisterForm.vue';
import ForgotPasswordForm from './forms/ForgotPasswordForm.vue';
import ForgotPasswordThankYou from './forms/ForgotPasswordThankYou.vue';
import {
  ForgotPasswordFormFields, LoginFormFields, RegisterFormFields, FormName,
} from './forms/types';
import { useCustomerStore } from '~/modules/customer/stores/customer';
import useFTGetCustomerGroupId from '~/modules/fortytwo/customer/composables/useFTGetCustomerGroupId';

export default defineComponent({
  name: 'LoginModal',
  components: {
    SfModal,
    SfBar,
    LoginForm,
    RegisterForm,
    ForgotPasswordForm,
    ForgotPasswordThankYou,
  },
  setup(_, { emit }) {
    const { isLoginModalOpen, isForgetPasswordForm, toggleForgetPasswordForm } = useUiState();

    const {
      user,
      register,
      login,
      loading,
      error: userError,
      load: loadUser 
    } = useUser();

    const { load: loadCart } = useCart();
    const { request: resetPassword, error: forgotPasswordError, loading: forgotPasswordLoading } = useForgotPassword();
    const customerStore = useCustomerStore();
    const { triggerRefreshPage } = useCustomerStore();
    const router = useRouter();
    const currentlyDisplayedForm = ref<FormName>('login');
    const barTitle = computed(() => {
      const mapFormNameToTopBarLabel : Record<FormName, string> = {
        login: 'Sign in',
        register: 'Register',
        forgotPassword: 'Reset Password',
        forgotPasswordThankYou: 'Thank you',
      };

      return mapFormNameToTopBarLabel[currentlyDisplayedForm.value];
    });

    const loginForm = ref<LoginFormFields>({
      email: '',
      password: '',
    });
    const registerForm = ref<RegisterFormFields>({
      email: '',
      firstName: '',
      lastName: '',
      password: '',
      passwordConfirmation: '',
      shouldSubscribeToNewsletter: true,
      isWillToCreateAccountConfirmed: true,
    });
    const forgotPasswordForm = ref<ForgotPasswordFormFields>({
      username: '',
    });

    // @ts-expect-error Recaptcha is not registered as a Nuxt module. Its absence is handled in the code
    const { $recaptcha, $config } = useContext();
    const isRecaptchaEnabled = ref<boolean>(typeof $recaptcha !== 'undefined' && Boolean($config.isRecaptcha));

    onMounted(async () => {
      if (isRecaptchaEnabled.value){
        $recaptcha.init();
      }
      if (isForgetPasswordForm.value) {
        toggleForgetPasswordForm();
        currentlyDisplayedForm.value = 'forgotPassword';
      }
    })
    const getRecaptchaInfo = async (isRecaptchaOn: boolean) : Promise<{ token: string | null, cleanup: () => void }> => {
      if (isRecaptchaOn) {
        return { token: await $recaptcha.execute(), cleanup: () => { $recaptcha.destroy(); } };
      }
      return { token: null, cleanup: () => {} };
    };

    const { getCustomerGroupId } = useFTGetCustomerGroupId();  
    const customerGroupId = ref(0);
    const fetchCustomerGroupId = async () => {
      const { data } = await getCustomerGroupId();
      customerGroupId.value = data?.getCustomerGroupId?.cust_grp_id ?? 0;
      await customerStore.setCustomerGroupId(customerGroupId.value);
    };

    const onLoginFormSubmit = async (form: LoginFormFields) => {
      loginForm.value = form;
      // const { token, cleanup } = await getRecaptchaInfo(isRecaptchaEnabled.value); // remove recaptcha from login
      // cleanup(); // remove recaptcha from login
      await login({ user: { ...form } });
      // cleanup(); // remove recaptcha from login
      if (!userError.value.login) {
        emit('close');
        await Promise.all([loadCart(), loadUser()]);
        if(user?.value?.email) {
          await fetchCustomerGroupId();
        }
        if(triggerRefreshPage){
          customerStore.setTriggerRefreshPage(false);
          router.go(0);
        }
      }
    };

    const onRegisterFormSubmit = async (form: RegisterFormFields) => {
      registerForm.value = form;
      const { token, cleanup } = await getRecaptchaInfo(isRecaptchaEnabled.value);
      await register({
        user: {
          email: form.email,
          firstname: form.firstName,
          lastname: form.lastName,
          password: form.password,
          is_subscribed: form.shouldSubscribeToNewsletter,
          ...(token ? { recaptchaToken: token } : {}),
        },
      });
      cleanup();
      if (!userError.value.register) {
        emit('close');
        await Promise.all([loadCart(), loadUser()]);
        if(user?.value?.email) {
          await fetchCustomerGroupId();
        }
      }
    };

    const onForgotPasswordFormSubmit = async (form: ForgotPasswordFormFields) => {
      forgotPasswordForm.value = form;
      const { token, cleanup } = await getRecaptchaInfo(isRecaptchaEnabled.value);
      await resetPassword({ email: form.username, ...(token ? { recaptchaToken: token } : {}) });
      cleanup();
      currentlyDisplayedForm.value = 'forgotPasswordThankYou';
    };

    return {
      isLoginModalOpen: computed(() => customerStore.triggerLoginModal),

      userError,
      loading,

      forgotPasswordError,
      forgotPasswordLoading,

      currentlyDisplayedForm,
      barTitle,

      loginForm,
      registerForm,
      forgotPasswordForm,

      isRecaptchaEnabled,

      onRegisterFormSubmit,
      onLoginFormSubmit,
      onForgotPasswordFormSubmit,
    };
  },
});
